import React from 'react'

export const InVisionIcon = () => {
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <title>InVision</title>
      <g fillRule="nonzero" fill="none" opacity=".7">
        <path
          d="M36.374 0H3.626A3.626 3.626 0 000 3.626v32.748A3.626 3.626 0 003.626 40h32.748A3.626 3.626 0 0040 36.374V3.626A3.626 3.626 0 0036.374 0"
          fill="#5E5E5E"
        />
        <path
          d="M13.368 12.585c1.33 0 2.444-1.042 2.444-2.408 0-1.365-1.114-2.407-2.444-2.407-1.33 0-2.443 1.042-2.443 2.407 0 1.366 1.113 2.408 2.443 2.408M8.301 25.48a8.27 8.27 0 00-.215 1.809c0 2.12 1.15 3.528 3.593 3.528 2.027 0 3.67-1.204 4.853-3.147l-.723 2.899h4.025l2.3-9.225c.575-2.335 1.689-3.548 3.378-3.548 1.33 0 2.155.827 2.155 2.192 0 .396-.035.826-.18 1.294l-1.185 4.24a6.373 6.373 0 00-.251 1.796c0 2.013 1.185 3.486 3.665 3.486 2.12 0 3.809-1.365 4.743-4.635l-1.581-.61c-.79 2.19-1.473 2.586-2.013 2.586-.539 0-.826-.359-.826-1.077 0-.323.072-.682.18-1.114l1.15-4.131a9.11 9.11 0 00.395-2.62c0-3.091-1.869-4.704-4.132-4.704-2.12 0-4.277 1.912-5.355 3.925l.79-3.613h-6.144l-.862 3.182h2.875l-1.77 7.088c-1.39 3.09-3.944 3.14-4.265 3.069-.526-.12-.863-.319-.863-1.003 0-.394.072-.961.252-1.644l2.695-10.692H8.158l-.863 3.182h2.839L8.302 25.48"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
